import React from "react";
import "./FavouriteForm.css";

function FavouriteForm(props) {
  // Submit handler handles the forms inputs and stores that new data into our array of 'favouriteData
  const submitHandler = (e) => {
    e.preventDefault();
    let name = document.getElementById("name").value;
    let url = document.getElementById("url").value;
    let newData = [
      {
        id: Math.random().toString(),
        Name: name,
        url: url,
        class: "shortcuts",
      },
    ];

    localStorage.setItem("newData", JSON.stringify(newData));
    mergeArray();
    props.add();
    // This grabs the function passed from parent to close modal when form is submitted. This alters state from home.jsx
    props.exit(false);
  };

  const mergeArray = () => {
    let newData = JSON.parse(localStorage.getItem("newData"));
    let oldData = JSON.parse(localStorage.getItem("oldData")) || [];

    oldData = oldData.concat(newData);
    localStorage.setItem("oldData", JSON.stringify(oldData));
  };

  return (
    <form className="form-container" onSubmit={submitHandler}>
      <label htmlFor="">website name</label>
      <input
        placeholder="eg: Facebook"
        type="text"
        name=""
        id="name"
        maxLength={10}
      />
      <label htmlFor="">url</label>
      <input placeholder="eg: facebook.com" type="text" name="" id="url" />
      <button className="formSub">Add to Favourites</button>
    </form>
  );
}

export default FavouriteForm;
