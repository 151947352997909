import React from "react";
import Button from "../Button/Button";
import Card from "../Card/Card";
import "./FavouriteCards.css";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function FavouriteCards(props) {
  function removeCard(e) {
    let oldData = JSON.parse(localStorage.getItem("oldData"));
    let selectedCard = e.target.id;
    oldData = oldData.filter((site) => site.id !== selectedCard);
    localStorage.setItem("oldData", JSON.stringify(oldData));
    props.remove();
  }

  function createShortcut(x) {
    return (
      <Card className={x.class} key={x.id}>
        <IconButton
          className="delFave"
          aria-label="delete"
          onClick={removeCard}
          id={x.id}
        >
          <DeleteIcon onClick={removeCard} id={x.id} />
        </IconButton>
        <h3 className="link-name">{x.Name}</h3>
        <Button link={"https://" + x.url} name="Go!" />
      </Card>
    );
  }

  return (
    <div className="favourite-section">
      {props.faves.map(createShortcut)}
      <Card className="add-cards">
        <button onClick={props.onButtonClick} className="add-card">
          +
        </button>
      </Card>
    </div>
  );
}

export default FavouriteCards;
