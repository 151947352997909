import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "../Card/Card";

function WeatherCard(props) {
  const [weather, setWeather] = useState();
  const [icon, setIcon] = useState();
  const [city, setCity] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const options = {
    enableHighAccuracy: true,
  };

  // This allows us to get the lat and lon position on load without affect sync functions
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.log(error);
      },
      options
    );
  }, []);

  // This then allows us to only call the fetch request once we have the geotag location from browser
  useEffect(() => {
    if (latitude && longitude) {
      axios
        .get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=2ffd9582b4b57a4f7c33fcb8f4e2e5c6&units=metric`
        )
        .then((response) => {
          setCity(response.data.name);
          setWeather(Math.round(response.data.main.temp));
          setIcon(
            "https://openweathermap.org/img/wn/" +
              response.data.weather[0].icon +
              ".png"
          );
        });
    }
  }, [latitude, longitude]);

  // Passing the city that we captured from the weather api up to parent (home.jsx)
  props.getCity(city);

  return (
    <Card className="temp-box">
      <img src={icon} width="50px" alt="" />
      <h3>Current Temp:</h3>
      <h3>{weather}°C</h3>
    </Card>
  );
}

export default WeatherCard;
