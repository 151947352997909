import React, { useState } from "react";
import Header from "../components/Header/Header";
import Time from "../components/Time/Time";
import WeatherCard from "../components/WeatherCard/WeatherCard";
import FavouriteCards from "../components/FavouriteCards/FavouriteCards";
import FavouriteModal from "../components/FavouriteModal/FavouriteModal";

function Home() {
  let faveData = JSON.parse(localStorage.getItem("oldData")) || [];
  const [openModal, setOpenModal] = useState(false);
  const [faveCards, setFaveCards] = useState(faveData);
  const [onCity, setOnCity] = useState();

  // Our function to pass the saved city from the weathercard comp to here using state to save that info and then pass on to time comp
  function saveCity(cities) {
    setOnCity(cities);
  }

  const rerenderFaves = () => {
    let faveData = JSON.parse(localStorage.getItem("oldData"));
    setFaveCards(faveData);
  };

  function handleButtonClick() {
    setOpenModal(true);
  }

  return (
    <div>
      <Header />
      <section className="section">
        <WeatherCard getCity={saveCity} />
        <Time setCity={onCity} />
        <FavouriteCards
          onButtonClick={handleButtonClick}
          faves={faveCards}
          remove={rerenderFaves}
        />
        {openModal && (
          <FavouriteModal closeModal={setOpenModal} faves={rerenderFaves} />
        )}
      </section>
    </div>
  );
}

export default Home;
